import classNames from 'classnames';
import moment from 'moment';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ReactComponent as ChartIco } from '../../assets/bar-chart.svg';
import { ReactComponent as BoltIco } from '../../assets/bolt.svg';
import { ReactComponent as CollapsedIco } from '../../assets/collapsed.svg';
import { ReactComponent as ExpandedIco } from '../../assets/expanded.svg';
import Accordion from '../../components/accordion/accordion';
import ChartLegendTable from '../../components/charg-legend-table/chart-legend-table';
import DateRangePicker from '../../components/date-range-picker';
import DonutChart from '../../components/donut-chart/donut-chart';
import { FormCard, FormCardBody } from '../../components/form-card';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Pill from '../../components/pill';
import SessionsEnergyChart from '../../components/sessions-energy-chart/sessions-energy-chart';
import colorStyles from '../../components/style-utils';
import TabPanel, { Tab, TabContent, Tabs } from '../../components/tab-panel';
import { rateTypeTooltip } from '../../components/tag';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { RateServiceType, SessionsEnergyEntryDto, SessionsEnergySumDto, TimeGroupResolution } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatKWhEnergy } from '../../utils/format';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './charging-stations-energy-page.module.scss';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.PUBLIC, RateServiceType.UNDEFINED];
interface ChildModelItem {
  nodeCode: string;
  expanded: boolean;
  payload?: {
    nodeTitle: string;
    entries: SessionsEnergyEntryDto[];
    sum: SessionsEnergySumDto;
    resolution: TimeGroupResolution;
  };
}
type FilterParameters = {
  from?: string;
  to?: string;
  skip?: number | null;
  limit?: number | null;
  clientCode?: string;
  nodes?: string[];
};

export default function ChargingStationsEnergyPage() {
  const { _t } = useBetterTranslate('charging-stations-energy-page');

  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';

  const navigate = useBetterNavigate();

  const { user } = useAuth();
  const sessionUrlPrefix = user?.preferences.views.sessions.prefix;

  const [childrenModels, setChildrenModels] = useState<ChildModelItem[]>([]);

  const validators = { ...pagingValidator, ...hierarchyValidator, ...dateRangeValidator(MIN_DATE, MAX_DATE) };
  const [filterParams, setFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const fetchChildrenModels = async (code: string, areaCodes: string[], timezone: string, from?: string, to?: string) => {
    const promises = areaCodes.map((c) => {
      return api.stationsEnergy.getEnergy({ client: code, hierarchyNodeCodes: [c], from, to, timezone });
    });

    const responses = await Promise.all(promises);
    const results = responses.map((r) => r.data);
    const childrenModels = results.map((r): ChildModelItem => {
      return {
        nodeCode: r.clientContext.selectedNodes[0].code,
        expanded: false,
        payload: {
          nodeTitle: r.clientContext.selectedNodes[0].title,
          entries: r.entries,
          sum: r.sum,
          resolution: r.resolution,
        },
      };
    });

    setChildrenModels(childrenModels);
  };

  const navigateToChargingSessionsPage = (filter: { serviceType?: string[]; nodes?: string[] }) => {
    const link = `/${sessionUrlPrefix}-charging-sessions/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const onToggleExpand = (childModel: ChildModelItem) => {
    childModel.expanded = !childModel.expanded;
    setChildrenModels([...childrenModels]);
  };

  const onToggleFoldAll = () => {
    const expandAll = childrenModels.filter((child) => child.expanded === false).length > 0;
    for (let child of childrenModels) {
      child.expanded = expandAll;
    }
    setChildrenModels([...childrenModels]);
  };

  const navigateToStatusPage = () => {
    navigate(`/locations/status/${filterParams.clientCode}`, { nodes: filterParams.nodes });
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[], fromDate?: string, toDate?: string) => {
        if (!clientCode) return undefined;
        const response = await api.stationsEnergy.getEnergy({
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
          from: startOfDay(fromDate),
          to: endOfDay(toDate),
          timezone: getTimezone(),
        });
        if (response.error) {
          return response;
        }
        const data = response.data;
        const nodes2Fetch = data.clientContext.selectedHierarchy.filter((c) => c.type === 'area').map((item) => item.code);
        const childrenItems = nodes2Fetch.map((item): ChildModelItem => ({ nodeCode: item, expanded: true, payload: undefined }));

        setChildrenModels(childrenItems);
        fetchChildrenModels(data.clientContext.code, nodes2Fetch, getTimezone(), startOfDay(fromDate), endOfDay(toDate));
        return response;
      },
      map: (data) => {
        if (!data) return data;
        return {
          clientContext: data.clientContext,
          entries: data.entries,
          sum: data.sum,
          resolution: data.resolution,
        };
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to
  );

  return (
    <Page
      breadCrumb={[{ title: _t('Standort Übersicht'), href: '/location/status', active: true }]}
      fetching={fetchingMainModel}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={errMainModel}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={mainModel?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== mainModel?.clientContext?.code) {
                navigate(`/locations/energy/${clientCode}`, { nodes: selectedCodes });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(mainModel?.clientContext)}
            selectedNodes={filterParams.nodes}
          />
        </>
      }
    >
      {mainModel && (
        <FormCard phoneSize='full'>
          <FormCardBody>
            <TabPanel>
              <Tabs>
                <Tab txt={_t('Status')} ico={<ChartIco />} onClick={navigateToStatusPage}></Tab>
                <Tab active={true} txt={_t('Energie')} ico={<BoltIco />}></Tab>
              </Tabs>
              <TabContent active={true}>
                <FormCard tabletSize='full'>
                  <DateRangePicker
                    className={styles.datePicker}
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    selected={filterParams}
                    onChange={(range) => setFilterParams({ ...range })}
                    dateFormat={'YYYY-MM-DD'}
                  />
                </FormCard>
                <EnergyChartPanel
                  entries={mainModel.entries}
                  sum={mainModel.sum}
                  resolution={mainModel.resolution}
                  totalOverall={mainModel.sum.totalEnergy.value}
                  mainChart={false}
                  onOverallClick={() => navigateToChargingSessionsPage({ nodes: filterParams.nodes })}
                  onWorkClick={() => navigateToChargingSessionsPage({ serviceType: ['WORK'], nodes: filterParams.nodes })}
                  onEmployeeClick={() => navigateToChargingSessionsPage({ serviceType: ['EMPLOYEE'], nodes: filterParams.nodes })}
                  onPublicClick={() => navigateToChargingSessionsPage({ serviceType: ['PUBLIC'], nodes: filterParams.nodes })}
                  onUnknownClick={() => navigateToChargingSessionsPage({ serviceType: ['UNDEFINED'], nodes: filterParams.nodes })}
                />
              </TabContent>
            </TabPanel>
          </FormCardBody>
        </FormCard>
      )}

      {mainModel && childrenModels && (
        <>
          <FormCard phoneSize='full'>
            <FormCardBody>
              <button type='button' className={classNames(styles.toggleFoldAll)} onClick={onToggleFoldAll}>
                {childrenModels.filter((child) => child.expanded === false).length > 0 && (
                  <>
                    <ExpandedIco />
                    {_t('Alle Bereiche öffnen')}
                  </>
                )}
                {childrenModels.filter((child) => child.expanded === false).length <= 0 && (
                  <>
                    <CollapsedIco />
                    {_t('Alle Bereiche schließen')}
                  </>
                )}
              </button>
            </FormCardBody>
          </FormCard>

          {childrenModels.map((childModel, idx) => {
            return (
              <FormCard key={idx} phoneSize='full'>
                <FormCardBody>
                  {!childModel.payload && <ListingCardPlaceholder fullWidth={true} cardSize={2} cardNumber={1} />}
                  {childModel.payload && (
                    <Accordion
                      expanded={childModel.expanded}
                      headline={
                        <div className={classNames(styles.accordionHeadline)}>
                          <NodeHierarchyLabelInverted
                            className={styles.panelNodeHierarchy}
                            hideArea={false}
                            hideClientRoot={true}
                            allNodes={[...mainModel.clientContext.accessableNodes, ...mainModel.clientContext.forbiddenParents]}
                            code={childModel.nodeCode}
                          />
                          <div className={classNames(styles.panelSummary, childModel.expanded ? styles.noDisplay : undefined)}>
                            <div className={styles.pillAndNumber} title={_t('Work')}>
                              <Pill className={classNames(colorStyles.components.rateType.work, styles.pill)}>&nbsp;</Pill>
                              <span>{formatKWhEnergy(Math.round(childModel.payload.sum.workEnergy.value), user?.preferences.languageCode)}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Employee')}>
                              <Pill className={classNames(colorStyles.components.rateType.employee, styles.pill)}>&nbsp;</Pill>
                              <span>{formatKWhEnergy(Math.round(childModel.payload.sum.employeeEnergy.value), user?.preferences.languageCode)}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Public')}>
                              <Pill className={classNames(colorStyles.components.rateType.public, styles.pill)}>&nbsp;</Pill>
                              <span>{formatKWhEnergy(Math.round(childModel.payload.sum.publicEnergy.value), user?.preferences.languageCode)}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Unknown')}>
                              <Pill className={classNames(colorStyles.components.rateType.unknown, styles.pill)}>&nbsp;</Pill>
                              <span>{formatKWhEnergy(Math.round(childModel.payload.sum.undefinedEnergy.value), user?.preferences.languageCode)}</span>
                            </div>
                          </div>
                          <div className={classNames(styles.energyLbl)}>
                            <div className={styles.chargedEnergy}>{`${_t('Geladene Energie')}: ${formatKWhEnergy(
                              childModel.payload.sum.totalEnergy.value,
                              user?.preferences.languageCode
                            )}`}</div>
                          </div>
                        </div>
                      }
                      onExpand={() => onToggleExpand(childModel)}
                    >
                      <EnergyChartPanel
                        entries={childModel.payload.entries}
                        sum={childModel.payload.sum}
                        resolution={childModel.payload.resolution}
                        totalOverall={mainModel.sum.totalEnergy.value}
                        onOverallClick={() => navigateToChargingSessionsPage({ nodes: [childModel.nodeCode] })}
                        onWorkClick={() => navigateToChargingSessionsPage({ serviceType: ['WORK'], nodes: [childModel.nodeCode] })}
                        onEmployeeClick={() => navigateToChargingSessionsPage({ serviceType: ['EMPLOYEE'], nodes: [childModel.nodeCode] })}
                        onPublicClick={() => navigateToChargingSessionsPage({ serviceType: ['PUBLIC'], nodes: [childModel.nodeCode] })}
                        onUnknownClick={() => navigateToChargingSessionsPage({ serviceType: ['UNDEFINED'], nodes: [childModel.nodeCode] })}
                      />
                    </Accordion>
                  )}
                </FormCardBody>
              </FormCard>
            );
          })}
        </>
      )}
    </Page>
  );
}

function EnergyChartPanel(
  props: PropsWithChildren<{
    entries: SessionsEnergyEntryDto[];
    sum: SessionsEnergySumDto;
    resolution: TimeGroupResolution;
    totalOverall: number;
    mainChart?: boolean;
    onOverallClick?: () => void;
    onWorkClick?: () => void;
    onEmployeeClick?: () => void;
    onPublicClick?: () => void;
    onUnknownClick?: () => void;
  }>
) {
  const { _t } = useBetterTranslate('charging-stations-energy-page');
  const { user } = useAuth();
  return (
    <div className={styles.chartRow}>
      <div className={props.mainChart ? styles.mainChart : styles.chart}>
        <div className={styles.donut}>
          <h4>{_t('Geladene Energie')}</h4>
          <DonutChart
            className={colorStyles.components.stationStatus.colors.__define}
            counterClockWise={false}
            items={[
              { color: colorStyles.components.stationStatus.colors.total, value: props.sum.totalEnergy.value },
              { color: colorStyles.components.stationStatus.colors.transparent, value: props.totalOverall - props.sum.totalEnergy.value },
            ]}
          >
            <span className={styles.chartTitle}>{formatKWhEnergy(props.sum.totalEnergy.value, user?.preferences.languageCode)}</span>
          </DonutChart>
        </div>
        <div className={styles.legend}>
          <ChartLegendTable
            items={[
              {
                title: _t('Gesamt'),
                dataCy: 'total',
                value: `${formatKWhEnergy(props.sum.totalEnergy.value, user?.preferences.languageCode)}`,
                pillClass: colorStyles.components.rateType.all,
                className: styles.gesamt,
                onClick: props.onOverallClick,
              },
              {
                title: _t('Work'),
                dataCy: 'work',
                value: `${formatKWhEnergy(props.sum.workEnergy.value, user?.preferences.languageCode)}`,
                pillClass: colorStyles.components.rateType.work,
                onClick: props.onWorkClick,
                tooltip: rateTypeTooltip(RateServiceType.WORK),
              },
              {
                title: _t('Employee'),
                dataCy: 'employee',
                value: `${formatKWhEnergy(props.sum.employeeEnergy.value, user?.preferences.languageCode)}`,
                pillClass: colorStyles.components.rateType.employee,
                onClick: props.onEmployeeClick,
                tooltip: rateTypeTooltip(RateServiceType.EMPLOYEE),
              },
              {
                title: _t('Public'),
                dataCy: 'public',
                value: `${formatKWhEnergy(props.sum.publicEnergy.value, user?.preferences.languageCode)}`,
                pillClass: colorStyles.components.rateType.public,
                onClick: props.onPublicClick,
                tooltip: rateTypeTooltip(RateServiceType.PUBLIC),
              },
              {
                title: _t('Unbekannt'),
                dataCy: 'unbekannt',
                value: `${formatKWhEnergy(props.sum.undefinedEnergy.value, user?.preferences.languageCode)}`,
                pillClass: colorStyles.components.rateType.unknown,
                onClick: props.onUnknownClick,
                tooltip: rateTypeTooltip(RateServiceType.UNDEFINED),
              },
            ]}
          />
        </div>
      </div>

      <SessionsEnergyChart
        className={styles.largestChart}
        loading={!props.entries}
        showLegend={false}
        serviceTypes={SERVICE_TYPE_ALLOWED_VALUES}
        allowedServiceTypes={SERVICE_TYPE_ALLOWED_VALUES}
        resolution={props.resolution}
        entries={props.entries}
        sum={props.sum}
      />
    </div>
  );
}
